import { createApp } from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import 'vant/lib/index.css'
import Icon from 'vant'

import { createRouter, createWebHashHistory } from 'vue-router'
//import VueRouter from 'vue-router'
import Index from './components/Index.vue'
import AndroidPlayer from './components/AndroidPlayer.vue'
import ShiBu from './components/ShiBu.vue'
// import VideoCtlDemo from './components/VideoCtlDemo.vue'

const routes = [
    { path: '/', redirect: '/ShiBu' },
    // { path: '/', redirect: '/Ctl2' },
    { path: '/All', component: Index },
    { path: '/Ctl', component: AndroidPlayer },
    // { path: '/Ctl2', component: VideoCtlDemo },
    { path: '/ShiBu', component: ShiBu }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

createApp(App).use(router).use(Icon).use(VueAxios, axios).mount('#app')
