<template>
<!-- <AndroidPlayer />-->
<!--   <ShiBu />-->
<!--   <Index />-->
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Index from './components/Index.vue'
import AndroidPlayer from './components/AndroidPlayer.vue'
import ShiBu from './components/ShiBu.vue'

export default {
  name: 'App',
  components: {
    Index,
    AndroidPlayer,
    ShiBu
  }
}
</script>

<style>
</style>
