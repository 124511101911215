<template>
    <div class="body">
        <div class="control_body">

            <div class="camera_body" ref="camera_body">
                <div id="divPlugin" @click="playerClick($event)" ref="divPlugin" class="plugin"></div>
            </div>

            <div>

                <div class="icon-fj">
                    <div class="tubiao jingyin" v-show="false"></div>
                    <div class="tubiao luzhi" @click="startRecording" v-show="false"></div>
                    <div class="tubiao paizhao" v-show="false"></div>
                    <div class="tubiao quanping" @click="fullScreen()"></div>
                </div>

                <div style="background-color: #efefef; text-align: left; width: 98%; padding: 5px">
                    摄像头设备：
                    <select
                            v-model="current_camera_index"
                            class="select11"
                            style="width: 60% ; backgtound-color: #00000000"
                            @change="changeCamera()"
                    >
                        <option
                                :value="index"
                                v-for="(camera, index) in cameras"
                                :key="index"
                                selected="selected"
                        >
                            {{ camera.cameraName }} ({{ camera.onlineStatus.status }})
                        </option>
                    </select>
                </div>

            </div>

            <!-- 摄像头控制 -->
            <van-row justify="center">
                <van-col span="3">
                    <div class="focus_div_top" style="text-align: center; width:40px">
                        <van-button
                                class="zoom_top_btn"
                                style="background-color: #efefef;margin:20px 0 0 20px"
                                @touchstart="PTZZoomIn($event)"
                                @touchend="PTZZoomStop($event)"
                                @mousedown="PTZZoomIn($event)"
                                @mouseout="PTZZoomStop($event)"
                                @mouseup="PTZZoomStop($event)"
                        >+</van-button>
                        <br />
                        <van-button
                                class="zoom_bottom_btn"
                                style="background-color: #efefef; margin:1px 0 0 20px;"
                                @touchstart="PTZZoomOut($event)"
                                @touchend="PTZZoomStop($event)"
                                @mousedown="PTZZoomOut($event)"
                                @mouseout="PTZZoomStop($event)"
                                @mouseup="PTZZoomStop($event)"
                        >-</van-button
                        >
                    </div>
                </van-col>
                <!-- 云台控制 -->
                <van-col span="18">
                    <div class="ctl_btn_div">
                        <div style="text-align: center;">
                            <span ref="ctlBtnTop" class="ctl_btn ctl_btn_top"
                                  :class="!ctlBtnTopActive ? 'ctl_btn_top' : 'ctl_btn_top_active'"
                                  @touchstart="mouseDownPTZControl(1, $event)"
                                  @touchend="mouseUpPTZControl($event)"
                                  @mousedown="mouseDownPTZControl(1, $event)"
                                  @mouseout="mouseUpPTZControl($event)"
                                  @mouseup="mouseUpPTZControl($event)"
                            />
                        </div>
                        <div style="text-align: center;" >
                            <span ref="ctlBtnLeft" class="ctl_btn ctl_btn_left_right"
                                  :class="!ctlBtnLeftActive ? 'ctl_btn_left' : 'ctl_btn_left_active'"
                                  @touchstart="mouseDownPTZControl(3, $event)"
                                  @touchend="mouseUpPTZControl($event)"
                                  @mousedown="mouseDownPTZControl(3, $event)"
                                  @mouseout="mouseUpPTZControl($event)"
                                  @mouseup="mouseUpPTZControl($event)"
                                  />

                            <span ref="ctlBtnRight" class="ctl_btn ctl_btn_left_right"
                                  :class="!ctlBtnRightActive ? 'ctl_btn_right' : 'ctl_btn_right_active'"
                                  @touchstart="mouseDownPTZControl(4, $event)"
                                  @touchend="mouseUpPTZControl($event)"
                                  @mousedown="mouseDownPTZControl(4, $event)"
                                  @mouseout="mouseUpPTZControl($event)"
                                  @mouseup="mouseUpPTZControl($event)"
                            />
                        </div>
                        <div style="text-align: center;">
                            <span ref="ctlBtnBottom" class="ctl_btn"
                                  :class="!ctlBtnBottomActive ? 'ctl_btn_bottom' : 'ctl_btn_bottom_active'"
                                  @mousedown="mouseDownPTZControl(2, $event)"
                                  @touchstart="mouseDownPTZControl(2, $event)"
                                  @touchend="mouseUpPTZControl($event)"
                                  @mouseout="mouseUpPTZControl($event)"
                                  @mouseup="mouseUpPTZControl($event)"
                            />
                        </div>
                    </div>

                </van-col>

                <van-col span="2">
                    <div class="focus_div_top" style="padding: 20px 20px 0px 0px; text-align: center; width:30px;">
                                                        <!-- 对焦 -->
                        <!-- <span style="font-size:12px">{{'对焦'}}</span> -->
                        <van-button
                                class="focus_top_btn"
                                @mousedown="PTZFocusOut($event)"
                                @touchstart="PTZFocusOut($event)"
                                @touchend="PTZZoomStop($event)"
                                @mouseout="PTZZoomStop($event)"
                                @mouseup="PTZZoomStop($event)"
                        >+</van-button
                        ><br />
                        <van-button
                                class="focus_bottom_btn"
                                style="background-color: #efefef"
                                @mousedown="PTZFocusIn($event)"
                                @touchstart="PTZFocusIn($event)"
                                @touchend="PTZFocusStop($event)"
                                @mouseout="PTZFocusStop($event)"
                                @mouseup="PTZFocusStop($event)"
                        >-</van-button
                        >
                    </div>
                </van-col>
                <van-col span="1"> &nbsp; </van-col>
            </van-row>

            <!-- <van-row class="preset-control-title" justify="space-around" style="padding-left: 8px">
                <van-col span="3">
                    <span>ID</span>
                </van-col>
                <van-col span="14">
                    <span>预置点编号</span>
                </van-col>
                <van-col span="7">
                    <span>预置点别名</span>
                </van-col>
            </van-row> -->

            <!-- <van-row class="preset-control" justify="space-around" style="margin-top: 5px;">
                <van-col span="3">
                    <select class="" v-model="presetId" style="width: 90%; height:35px">
                        <option
                                :value="preset.presetId"
                                :label="preset.presetName"
                                v-for="preset in allowPresets"
                                :key="preset.presetId"
                                selected="selected"
                        >
                        </option>
                    </select>
                </van-col>
                <van-col span="14">
                    <input style="width: 90%;height:30px;margin-left: 10px; "
                           v-model="inputPresetCode"
                           label=""
                           readonly
                           placeholder="" />
                </van-col>
                <van-col span="7">
                    <input style="width: 90%; height:30px"
                           v-model="inputPresetName"
                           label="文本"
                           placeholder=""/>
                </van-col>
            </van-row>
            <van-row justify="space-around" style="margin-top: 15px">
                <van-col span="12" align="center">
                    <van-button class="buttom_round" type="primary" @click="setPreset">设置</van-button>
                </van-col>
                <van-col span="12" align="center">
                    <van-button class="buttom_round" type="warning" @click="gotoPreset">调用</van-button>
                </van-col>
            </van-row> -->

        </div>

<!--        width: 20px; height: 20px;-->
        <div v-show="presetPointVisible" ref="presetPoint" style="  position: fixed; z-index: 9999;
                left:0;right:0;top:0;bottom:0; ">
            <img style="width: 100%; height: 100%" src="../assets/preset_point.png" />
        </div>
      {{ log }}
    </div>
</template>

<script>
    // const g_iWndIndex = 0;

    // import {initPlayer} from '../js/CameraCtl'
    // import vue
    import { Slider, Button, Col, Row, Field, CellGroup, Popup } from "vant";

    export default {
        name: "AndroidPlayer",
        components: {
            [Field.name]: Field,
            [CellGroup.name]: CellGroup,
            [Button.name]: Col,
            [Slider.name]: Row,
            [Button.name]: Button,
            [Slider.name]: Slider,
            [Popup.name]: Popup,
        },
        data() {
            return {
                log: '',
                liveWidth: 1280,
                liveHeight: 720,

                /// 预置点画面画点相关
                presetPointSize: 20,   // 圆点像素
                presetPointVisible: false,
                presetOffsetX: 0,
                presetOffsetY: 0,
                pointDrawClicked: false,

                djvalue: 100,
                showHistory: true,
                popupshow: false,
                heightplugin: [
                    // {value:}
                ],
                movie: "",
                cameras: [
                    // { // 阿里
                    //   cameraName: '',
                    //   cameraIp: '',// 必选参数
                    //   cameraAdminPort: 0,    // 必选参数
                    //   username: '',    // 必选参数
                    //   password: '',     // 必选参数
                    //   camera_device_port: 0, // 可选参数
                    //   cameraWsPort: 0,
                    //   status: {
                    //     code: 0,
                    //     status: "离线"
                    //   }
                    //   // rtsp_port: 554,            // 可选参数
                    // },
                    // { // 阿里
                    //   cameraName: 'KGX-LIVE-OL-01',
                    //   cameraIp: '127.0.0.1',// 必选参数
                    //   cameraAdminPort: 2800,    // 必选参数
                    //   username: 'admin',    // 必选参数
                    //   password: 'ssd123456',     // 必选参数
                    //   camera_device_port: 18000, // 可选参数
                    //   cameraWsPort: 17000,
                    //   // rtsp_port: 554,            // 可选参数
                    // },
                    //
                    // {
                    //   cameraName: 'KGX-LIVE-OL-02',
                    //   cameraIp: '127.0.0.1',
                    //   cameraAdminPort: 1801,
                    //   username: 'admin',
                    //   password: 'ssd123456',
                    //   camera_device_port: 18001,
                    //   cameraWsPort: 17001,
                    //   // rtsp_port: 554,
                    // },
                    // {
                    //   cameraName: 'KGX-LIVE-01',
                    //   cameraIp: '192.168.0.64',// 必选参数
                    //   cameraAdminPort: 80,    // 必选参数
                    //   username: 'admin',    // 必选参数
                    //   password: 'ssd123456',     // 必选参数
                    //   cameraWsPort: 7681,
                    // },
                    // {
                    //   cameraName: 'KGX-LIVE-02',
                    //   cameraIp: '192.168.0.65',
                    //   cameraAdminPort: 80,
                    //   username: 'admin',
                    //   password: 'ssd123456',
                    //   cameraWsPort: 7681,
                    // },
                ],

                // 预置点相关
                // 预置点列表
                presets: [],
                // 预置点ID
                presetsIds: [],
                inputPresetName: "",
                inputPresetCode: "",

                // 可用的
                allowPresets: [],

                // 摄像头选择相关
                // 当前摄像头对象
                current_camera: null,
                // 所选的摄像头index
                current_camera_index: 0,

                // 截图相关
                takePictureUrl: null,

                // 录制相关
                isRecording: false,
                recordingSession: null,
                recordingUrl: null,
                recordingHeartbeat: false,
                // recordingUrl: '/KGX/IOT/res/videos/20220408/v_1_20220408112548_48.mp4',
                // recordingUrl: '/KGX/IOT/res/videos/20220408/dump.flv',

                // 摄像头控制
                g_bPTZAuto: false,
                bZeroChannel: false,
                speed: 4,     // 控制云台速度 , 数值越大 云台转向越快

                presetId: 1,
                current_preset: null,
                maxPreset: 20,

                ctlBtnLeftActive: false,
                ctlBtnRightActive: false,
                ctlBtnTopActive: false,
                ctlBtnBottomActive: false,

                debug_message: "未登录",
            };
        },
        created() {
            this.load_cameras();
        },
        mounted() {
            var screenWidth = document.documentElement.clientWidth;
            var width = screenWidth;
            if (!this.isMobile()) {
                width = screenWidth / 3;
                console.log("当前是PC端")
            } else {
                console.log("当前是移动端")
            }
            var height = (width * this.liveHeight) / this.liveWidth;

            var divPlugin = this.$refs.divPlugin;
            divPlugin.style.width = width + "px";
            divPlugin.style.height = height + "px";
        },
        methods: {

            //播放器点击
            playerClick(e) {
                var clickX = e.clientX;
                var clickY = e.clientY;
                this.pointDrawClicked = true;
                this.playerDraw(clickX, clickY);
            },
            isMobile() {
                if (
                    window.navigator.userAgent.match(
                        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            showPopup() {
                this.popupshow = true;
            },
            onChange(val) {
                console.log('val',val)
                this.djvalue = val;
                if (val< 50) {
                    //拉近
                    this.PTZZoomIn();
                    this.PTZZoomStop();
                } else {
                    // 拉远
                    this.PTZZoomOut();
                    this.PTZZoomStop();
                }
            },
            onConfirm(val, index) {
                console.log("val", val, index);

                this.popupshow = false;
            },
            onCancel() {
                this.popupshow = false;
            },
            load_cameras() {
                this.$http({
                    url: "/KGX/IOT/api/camera/list",
                    method: "get",
                    params: {},
                }).then(
                    (response) => {
                        this.cameras = response.data.data;
                        if (this.cameras.length > 0) {
                            console.log("获取列表成功");
                            this.current_camera_index = 0;
                            this.current_cameras = this.cameras[0];
                            console.log(JSON.stringify(this.current_cameras));
                            console.log("开始初始化");
                            this.initPlayer();
                            console.log("开始播放");
                            this.loginDevice();
                            // this.getPreset();
                        }
                    },
                    (onerror) => {}
                );
            },
            changeWndNum() {
                // var iType = parseInt("1", 10);
                window.WebVideoCtrl.I_ChangeWndNum(1);
            },
            initPlayer() {

                var iRet = window.WebVideoCtrl.I_CheckPluginInstall();

                // if (-1 == iRet) {
                //   alert("您的浏览器不兼容！");
                //   return;
                // }

                var screenWidth = document.documentElement.clientWidth;
                var width = screenWidth;
                if (!this.isMobile()) {
                    width = screenWidth / 3;
                }
                var height = (width * this.liveHeight) / this.liveWidth;

                // var camera_body = this.$refs.camera_body;
                // camera_body.style.height = "200px";

                // var height = (screenWidth * 720) / 1280;

                WebVideoCtrl.I_InitPlugin(width, height, {
                    bWndFull: true, //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                    iPackageType: 2,
                    //szColorProperty:"plugin-background:0000ff; sub-background:0000ff; sub-border:00ffff; sub-border-select:0000ff",   //2:PS 11:MP4
                    iWndowType: 1,
                    bNoPlugin: true,
                    cbSelWnd: function (xmlDoc) {
                        g_iWndIndex = parseInt($(xmlDoc).find("SelectWnd").eq(0).text(), 10);
                        console.log("当前选择的窗口编号：" + g_iWndIndex);
                    },
                    cbDoubleClickWnd: function (iWndIndex, bFullScreen) {
                        var szInfo = "当前放大的窗口编号：" + iWndIndex;
                        if (!bFullScreen) {
                            szInfo = "当前还原的窗口编号：" + iWndIndex;
                        }
                        console.log(szInfo);
                    },
                    cbEvent: function (iEventType, iParam1, iParam2) {
                        if (2 == iEventType) {
                            // 回放正常结束
                            showCBInfo("窗口" + iParam1 + "回放结束！");
                        } else if (-1 == iEventType) {
                            showCBInfo("设备" + iParam1 + "网络错误！");
                        } else if (3001 == iEventType) {
                            clickStopRecord(g_szRecordType, iParam1);
                        }
                    },
                    cbRemoteConfig: function () {
                        // showCBInfo("关闭远程配置库！");
                    },
                    cbInitPluginComplete: function () {
                        // this.divPluginshow = false;
                        WebVideoCtrl.I_InsertOBJECTPlugin("divPlugin");
                        // 检查插件是否最新
                        if (-1 == WebVideoCtrl.I_CheckPluginVersion()) {
                            alert("您的浏览器不兼容！");
                            return;
                        }
                    },
                });
                // WebVideoCtrl.I_ChangeWndNum(parseInt("1", 10));
                console.log("初始化播放插件完毕。");
            },
            getParam(key) {
               const params = new URLSearchParams(window.location.search);
               return params.get(key);
            },
            // 登录设备
            loginDevice() {
                this.current_camera = this.cameras[this.current_camera_index];

                let ip = this.current_camera.cameraIp;
                let port = this.current_camera.cameraAdminPort;
                let type = "远程"
                if (this.getParam('type') == 'l') {
                  ip = this.current_camera.cameraRealIp;
                  port = this.current_camera.cameraRealAdminPort;
                  type = "本地"
                }

                console.log(
                    "开始登录设备: (" + type + ") " +
                    ip + " : " + port
                );
                this.log = "开始登录设备: (" + type + ") " +
                    ip + " : " + port

                var szIP = ip,
                    szPort = port,
                    szUsername = this.cameras[this.current_camera_index].username,
                    szPassword = this.cameras[this.current_camera_index].password;

                if ("" == szIP || "" == szPort) {
                    return;
                }

                var that = this;

                var szDeviceIdentify = szIP + "_" + szPort;

                that.log = "发起登录"
                var iRet = WebVideoCtrl.I_Login(szIP, 1, szPort, szUsername, szPassword, {
                    success: function (xmlDoc) {
                        setTimeout(function () {
                            console.log("设备登录成功");
                            that.log = "设备登录成功"
                            that.debug_message = "设备登录成功 摄像头IP: " + szDeviceIdentify;
                            that.playCamera();
                            // that.getChannelInfo();
                            // that.getDevicePort();
                        }, 10);
                    },
                    error: function (status, xmlDoc) {
                      that.log = "登录失败"
                      showOPInfo(szDeviceIdentify + " 登录失败！", status, xmlDoc);
                    },
                });

                if (-1 == iRet) {
                    showOPInfo(szDeviceIdentify + " 已登录过！");
                }
            },

            // 登出
            logoutDevice() {
                if (this.current_camera == null) return;

              let ip = this.current_camera.cameraIp;
              let port = this.current_camera.cameraAdminPort;
              let type = "远程"
              if (this.getParam('type') == 'l') {
                ip = this.current_camera.cameraRealIp;
                port = this.current_camera.cameraRealAdminPort;
                type = "本地"
              }

                var szDeviceIdentify = ip + "_" + port,
                    szInfo = "";

                if (null == szDeviceIdentify) {
                    return;
                }

                var iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                if (0 == iRet) {
                    szInfo = "退出成功！";
                    // getChannelInfo();
                    // getDevicePort();
                } else {
                    szInfo = "退出失败！";
                }
                showOPInfo(szDeviceIdentify + " " + szInfo);
                console.log(szDeviceIdentify + " " + szInfo + " 已经退出");
            },

            // 开始播放
            playCamera(iStreamType) {
                var that = this;

                let ip = this.current_camera.cameraIp;
                let adminPort = this.current_camera.cameraAdminPort;
                let rtspPort = this.current_camera.cameraRtspPort;
                let wsPort = this.current_camera.cameraWsPort;
                let type = "远程"
                if (this.getParam('type') == 'l') {
                  ip = this.current_camera.cameraRealIp;
                  rtspPort = this.current_camera.cameraRealRtspPort;
                  wsPort = this.current_camera.cameraRealWsPort;
                  adminPort = this.current_camera.cameraRealAdminPort;
                  type = "本地"
                }

                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
                    szDeviceIdentify = ip + "_" + adminPort,
                    iRtspPort = rtspPort,
                    iChannelID = 1,
                    bZeroChannel = this.bZeroChannel,
                    szInfo = "";

                console.log(
                    "开始播放 (" + type + ") : " + ip + " : " + wsPort
                );

                if ("undefined" === typeof iStreamType || iStreamType == "") {
                    // iStreamType = parseInt($("#streamtype").val(), 10);
                  iStreamType = parseInt("1", 10)
                  console.log("=====================" + iStreamType)
                }

                if (null == szDeviceIdentify) {
                    return;
                }
                var startRealPlay = function () {
                    console.log('开始执行 I_StartRealPlay ' + szDeviceIdentify + " " + iRtspPort + " " + iStreamType + " " + iChannelID + " " + bZeroChannel + " " + wsPort)
                    WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                        iRtspPort: iRtspPort,
                        iStreamType: iStreamType,
                        iChannelID: iChannelID,
                        bZeroChannel: bZeroChannel,
                        iWSPort: wsPort,
                        success: function () {
                            szInfo = "开始预览成功！";
                            showOPInfo(szDeviceIdentify + " " + szInfo);
                        },
                        error: function (status, xmlDoc) {
                            if (403 === status) {
                                szInfo = "设备不支持Websocket取流！";
                            } else {
                                szInfo = "开始预览失败！";
                            }
                            showOPInfo(szDeviceIdentify + " " + szInfo);
                          // alert("画面播放失败")
                        },
                    });
                };

                if (oWndInfo != null) {
                    // 已经在播放了，先停止
                    WebVideoCtrl.I_Stop({
                        success: function () {
                            startRealPlay();
                        },
                    });
                } else {
                    startRealPlay();
                }
            },

            fullScreen() {

                if (!this.isMobile()) {
                    WebVideoCtrl.I_FullScreen(true);
                    return;
                }

                var that = this;
                var player = this.$refs.divPlugin;
                var camera_body = this.$refs.camera_body;

                var screenWidth = document.documentElement.clientWidth;
                var screenHeight = document.documentElement.clientHeight;

                player.style.height=screenWidth+'px';
                player.style.width=screenHeight+'px';

                player.style.webkitTransform = "rotate(90deg)";
                player.style.mozTransform = "rotate(90deg)";
                player.style.msTransform = "rotate(90deg)";
                player.style.oTransform = "rotate(90deg)";
                player.style.transform = "rotate(90deg)";

                WebVideoCtrl.I_FullScreen(true);

                // var screenWidth = document.documentElement.clientHeight;
                // var width = screenWidth;
                // if (window.navigator.userAgent.match(
                //         /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                //     )) {
                //     console.log("移动端");
                //     width = screenWidth;
                // } else {
                //     console.log("PC端");
                //     width = screenWidth / 3;
                // }
                // var height = (screenWidth * 1080) / 1920;

                setTimeout(function () {

                    WebVideoCtrl.I_FullScreen(false);

                    player.style.marginLeft = "-" + (screenWidth/2) + "px";
                    player.style.marginTop = "" + (screenWidth/2) + "px";

                    // console.log( document.documentElement.clientHeight,'camera_body',camera_body)
                    // player.style.height=document.documentElement.clientwidht+'px';
                    // player.style.width=document.documentElement.clientHeight+'px';
                    // // camera_body.style.width=document.documentElement.clientwidht+'px';
                    // // camera_body.style.height=document.documentElement.clientHeight+'px';
                    // // WebVideoCtrl.I_FullScreen(true);
                    // let element = document.getElementById('divPlugin');
                    // let fullscreen=false
                    // if (fullscreen) {
                    //     // 如果已经全屏了就退出全屏
                    //     if (document.exitFullscreen) {
                    //         document.exitFullscreen();
                    //     } else if (document.webkitCancelFullScreen) {
                    //         document.webkitCancelFullScreen();
                    //     } else if (document.mozCancelFullScreen) {
                    //         document.mozCancelFullScreen();
                    //     } else if (document.msExitFullscreen) {
                    //         document.msExitFullscreen();
                    //     }
                    //     this.fullscreen = false
                    // } else {
                    //     // 如果不是全屏就变成全屏
                    //     if (element.requestFullscreen) {
                    //         element.requestFullscreen();
                    //     } else if (element.webkitRequestFullScreen) {
                    //         element.webkitRequestFullScreen();
                    //     } else if (element.mozRequestFullScreen) {
                    //         element.mozRequestFullScreen();
                    //     } else if (element.msRequestFullscreen) {
                    //         // IE11
                    //         element.msRequestFullscreen();
                    //     }
                    //
                    // }

                }, 100);

            },

            stopRealPlay() {
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
                    szInfo = "";

                if (oWndInfo != null) {
                    WebVideoCtrl.I_Stop({
                        success: function () {
                            szInfo = "停止预览成功！";
                            showOPInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                        },
                        error: function () {
                            szInfo = "停止预览失败！";
                            showOPInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                        },
                    });
                }
            },

            changeCamera() {
                console.log("选择摄像头: " + this.current_camera_index);
                this.presetPointVisible = false;
                this.presetOffsetX = 0;
                this.presetOffsetY = 0;
                this.stopRealPlay();
                this.logoutDevice();
                this.loginDevice();
                this.current_preset = null;
                // this.getPreset();
                this.playCamera();
            },

            // 云台控制, 鼠标点击按下时.
            // iPTZIndex 云台方向 1: 向上 2: 向下 3: 向左 4: 向右 5: 左上 6: 左下 7: 右上 8: 右下  9: 自动(一般不要用)
            mouseDownPTZControl(iPTZIndex, e) {
              console.log('1111')
                if (e.type.startsWith('mouse') && this.isMobile()) {
                  return
                }
                console.log('2222')
                if (iPTZIndex == 1) {
                    this.ctlBtnTopActive = true;
                } else if (iPTZIndex == 2) {
                    this.ctlBtnBottomActive = true;
                } else if (iPTZIndex == 3) {
                    this.ctlBtnLeftActive = true;
                } else if (iPTZIndex == 4) {
                    this.ctlBtnRightActive = true;
                }

                console.log("this.bZeroChannel=" + this.bZeroChannel + "  this.speed=" + this.speed)
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
                    bZeroChannel = this.bZeroChannel,
                    iPTZSpeed = this.speed;

                if (bZeroChannel) {
                    // 零通道不支持云台
                    return;
                }

                if (oWndInfo != null) {
                    if (9 == iPTZIndex && this.g_bPTZAuto) {
                        iPTZSpeed = 0; // 自动开启后，速度置为0可以关闭自动
                    } else {
                        this.g_bPTZAuto = false; // 点击其他方向，自动肯定会被关闭
                    }

                    WebVideoCtrl.I_PTZControl(iPTZIndex, false, {
                        iPTZSpeed: iPTZSpeed,
                        success: function (xmlDoc) {
                          if (9 == iPTZIndex) {
                            g_bPTZAuto = !g_bPTZAuto;
                          }
                          console.log("contril " + iPTZIndex + " success")
                        },
                        error: function (status, xmlDoc) {},
                    });
                }
            },

            // 云台控制, 鼠标点击抬起时
            mouseUpPTZControl(e) {
              if (e.type.startsWith('mouse') && this.isMobile()) {
                return
              }
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    console.log("释放");
                    WebVideoCtrl.I_PTZControl(1, true, {
                        success: function (xmlDoc) {},
                        error: function (status, xmlDoc) {},
                    });
                }

                // this.$refs.ctlBtnTop.style.content = '../assets/ctl_top.png';

                this.ctlBtnTopActive = false;
                this.ctlBtnRightActive = false;
                this.ctlBtnLeftActive = false;
                this.ctlBtnBottomActive = false;

            },

            /// 焦距控制
            PTZZoomIn(e) {
                if (e.type.startsWith("mouse") && this.isMobile()) {
                  return
                }
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                console.log("苏第四", oWndInfo)
                this.log = "zoomin start"
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(10, false, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 调焦+成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  调焦+失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            PTZZoomOut(e) {
                if (e.type.startsWith('mouse') && this.isMobile()) {
                  return
                }
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(11, false, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 调焦-成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  调焦-失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            PTZZoomStop(e) {
              if (e.type.startsWith("mouse") && this.isMobile()) {
                return
              }
              var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
              this.log = "zoominstop"
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(11, true, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 调焦停止成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  调焦停止失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            /// 对焦控制
            PTZFocusIn(e) {
                if (e.type.startsWith('mouse') && this.isMobile()) {
                  return
                }
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(12, false, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 聚焦+成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  聚焦+失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            PTZFocusOut(e) {
                if (e.type.startsWith('mouse') && this.isMobile()) {
                  return
                }
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(13, false, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 聚焦-成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  聚焦-失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            PTZFocusStop(e) {
                if (e.type.startsWith('mouse') && this.isMobile()) {
                  return
                }
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(12, true, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 聚焦停止成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  聚焦停止失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            /// 光圈控制
            PTZIrisIn() {
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(14, false, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 光圈+成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  光圈+失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            PTZIrisOut() {
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(15, false, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 光圈-成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  光圈-失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            PTZIrisStop() {
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_PTZControl(14, true, {
                        iWndIndex: g_iWndIndex,
                        success: function (xmlDoc) {
                            showOPInfo(oWndInfo.szDeviceIdentify + " 光圈停止成功！");
                        },
                        error: function (status, xmlDoc) {
                            showOPInfo(
                                oWndInfo.szDeviceIdentify + "  光圈停止失败！",
                                status,
                                xmlDoc
                            );
                        },
                    });
                }
            },

            getPresetById(presetId) {
                if (this.presets.length == 0) {
                    return null;
                }
                for (let i = 0; i < this.presets.length; i++) {
                    if (this.presets[i].presetId == presetId) {
                        return this.presets[i];
                    }
                }
            },

            /// 预置点设置
            setPreset() {

                this.setPresetAPI();

                // 直接发送相机预置点指令 ， 目前使用 this.setPresetAPI(); 由服务端来发送指令
                // var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
                //     iPresetID = parseInt(this.presetId, 10);
                // if (oWndInfo != null) {
                //   WebVideoCtrl.I_SetPreset(iPresetID, {
                //     success: function (xmlDoc) {
                //       console.log(oWndInfo.szDeviceIdentify + " 设置预置点成功！")
                //     },
                //     error: function (status, xmlDoc) {
                //       console.log(oWndInfo.szDeviceIdentify + " 设置预置点失败！")
                //     }
                //   });
                // }
            },

            // 调用IoT平台API 更新预置点信息
            setPresetAPI() {
                var cameraId = this.current_camera.id;
                var presetName = this.inputPresetName;
                var presetCode = this.inputPresetCode;
                var presetId = this.presetId;
                var that = this;
                console.log(
                    "设置预置点: cameraId=" +
                    cameraId +
                    " presetName=" +
                    presetName +
                    "  presetCode=" +
                    presetCode +
                    "   presetId=" +
                    presetId
                );
                var that = this;
                this.$http({
                    url: "/KGX/IOT/api/camera/setPresets",
                    method: "put",
                    params: {
                        cameraId: cameraId,
                        presetName: presetName,
                        // presetCode: presetCode,
                        presetId: presetId,
                        presetOffsetX: this.presetOffsetX,
                        presetOffsetY: this.presetOffsetY,
                    },
                }).then(
                    (response) => {
                        if (!response.data.success) {
                            alert("设置失败!");
                            return;
                        } else {
                            // that.getPreset();
                            alert("设置成功");
                            setTimeout(function () {
                                that.presetId = presetId;
                            }, 200);
                        }
                    },
                    (onerror) => {}
                );
            },

            deletePreset() {
                var that = this;
                this.$http({
                    url: "/KGX/IOT/api/camera/delPreset",
                    method: "delete",
                    params: {
                        cameraId: this.current_camera.id,
                        presetId: this.presetId
                    },
                }).then(
                    (response) => {
                        var data = response.data.data;
                    },
                    (onerror) => {}
                );
            },

            // 调用预置点
            gotoPreset() {
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(g_iWndIndex),
                    iPresetID = parseInt(this.presetId, 10);
                if (oWndInfo != null) {
                    WebVideoCtrl.I_GoPreset(iPresetID, {
                        success: function (xmlDoc) {
                            console.log(
                                oWndInfo.szDeviceIdentify +
                                "@" +
                                this.presetId +
                                " 调用预置点成功！"
                            );
                        },
                        error: function (status, xmlDoc) {
                            console.log(
                                oWndInfo.szDeviceIdentify +
                                "@" +
                                this.presetId +
                                " 调用预置点失败！"
                            );
                        },
                    });
                }
            },

            // 获取摄像头预制点
            getPreset() {
                var that = this;
                this.$http({
                    url: "/KGX/IOT/api/camera/presets",
                    // url: '/ISAPI/PTZCtrl/channels/1/presets',
                    method: "get",
                    params: {
                        cameraId: this.current_camera.id,
                    },
                }).then(
                    (response) => {
                        var data = response.data.data;
                        that.presets = data.presets;
                        if ( (that.current_preset == null && that.presets.length > 0) || that.presetId != null ) {

                            for (var i=0; i < that.presets.length; i++) {
                                var preset = that.presets[i];
                                console.log(preset.presetId + "==" + that.presetId);
                                if (preset.presetId == that.presetId) {
                                    that.current_preset = that.presets[i];
                                    break;
                                }
                            }

                            console.log("this.current_preset=" + JSON.stringify(that.current_preset));
                            that.inputPresetName = that.current_preset.presetName;
                            that.inputPresetCode = that.current_preset.presetCode;
                            console.log("this.inputPresetName=" + that.inputPresetName);
                            console.log("this.inputPresetCode=" + that.inputPresetCode);
                        }

                        // debugger
                        this.presetDraw();

                        this.presetId = this.current_preset.presetId;

                        var allowIds = data.allowPresetIds;

                        if (allowIds == null) return;

                        this.allowPresets = [];
                        for (let i = 0; i < allowIds.length; i++) {
                            var allowPreset = {};
                            allowPreset.presetName = allowIds[i];
                            for (let j = 0; j < data.presets.length; j++) {
                                if (data.presets[j].presetId == allowIds[i]) {
                                    allowPreset.presetName =
                                        allowIds[i] + " -> " + data.presets[j].presetCode;
                                    break;
                                }
                            }
                            allowPreset.presetId = allowIds[i];
                            this.allowPresets.push(allowPreset);
                        }

                    },
                    (onerror) => {}
                );
            },

            syncPreset() {
                this.$http({
                    url: "/KGX/IOT/api/camera/sys/presets/sync",
                    method: "get",
                    params: {
                        cameraId: this.current_camera.id,
                    },
                }).then(
                    (response) => {
                        // this.getPreset();
                        alert("同步完成");
                    },
                    (onerror) => {}
                );
            },

            // changeWndNum(iType) {
            //   iType = parseInt(iType, 10);
            //   WebVideoCtrl.I_ChangeWndNum(iType);
            // },

            // 截图
            takePicture() {
                var that = this;
                this.$http({
                    url: "/KGX/IOT/api/camera/picture",
                    // url: '/ISAPI/PTZCtrl/channels/1/presets',
                    method: "get",
                    params: {
                        cameraId: this.current_camera.id,
                    },
                }).then(
                    (response) => {
                        if (response.data.success) {
                            console.log("截图成功！ : " + response.data.data);
                            that.takePictureUrl = response.data.data;
                        }
                    },
                    (onerror) => {}
                );
            },

            // 视频录制
            startRecording() {
                var that = this;
                this.$http({
                    url: "/KGX/IOT/api/camera/startRecording",
                    method: "post",
                    params: {
                        cameraId: this.current_camera.id,
                    },
                }).then(
                    (response) => {
                        if (response.data.success) {
                            console.log("正在录制");
                            that.recordingSession = response.data.data.session;
                            that.isRecording = true;
                            that.recordingHeartbeat = true;
                            that.startRecordingHeartbeat(that.recordingSession);
                        }
                    },
                    (onerror) => {
                        console.log("录制失败");
                    }
                );
            },

            stopRecording() {
                var that = this;
                this.recordingHeartbeat = false;
                if (this.recordingSession == null) {
                    alert("未开始录制");
                    return;
                }
                this.$http({
                    url: "/KGX/IOT/api/camera/stopRecording",
                    method: "post",
                    params: {
                        session: this.recordingSession,
                    },
                }).then(
                    (response) => {
                        if (response.data.success) {
                            console.log("开始录制");
                            that.recordingSession = null;
                            that.isRecording = false;
                            that.recordingUrl = response.data.data;
                        }
                    },
                    (onerror) => {
                        console.log("录制失败");
                    }
                );
            },

            startRecordingHeartbeat(session) {
                this.$http({
                    url: "/KGX/IOT/api/camera/recordingHeartbeat",
                    method: "post",
                    params: {
                        session: session,
                    },
                }).then(
                    (response) => {},
                    (onerror) => {}
                );

                var that = this;
                setTimeout(function () {
                    if (that.recordingHeartbeat) {
                        console.log("发送录像心跳");
                        that.startRecordingHeartbeat(session);
                    }
                }, 5 * 1000);
            },

            /**
             *
             * @param x
             * @param y
             */
            playerDraw(x, y) {

                var divWidth = this.$refs.divPlugin.offsetWidth;
                var divHeight = this.$refs.divPlugin.offsetHeight;

                this.presetOffsetX = parseInt(this.liveWidth * (x / divWidth));
                this.presetOffsetY = parseInt(this.liveHeight * (y / divHeight));

                this.presetPointVisible = true;

                this.$refs.presetPoint.style.width = this.presetPointSize + "px";
                this.$refs.presetPoint.style.height = this.presetPointSize + "px";

                this.$refs.presetPoint.style.left = (x - (this.presetPointSize / 2) ) + "px";
                this.$refs.presetPoint.style.top = (y - (this.presetPointSize / 2)) + "px";

                console.log("3333333333333 > " + this.presetPointVisible + "  " + this.$refs.presetPoint.style.left + "  " + this.$refs.presetPoint.style.top);

            },

            presetDraw() {
                if (this.current_preset != null && this.current_preset.draw != null) {
                    console.log("presetDraw start");

                    var divWidth = this.$refs.divPlugin.offsetWidth;
                    var divHeight = this.$refs.divPlugin.offsetHeight;

                    var draw = JSON.parse(this.current_preset.draw);
                    var x = draw.x;
                    var y = draw.y;

                    x = parseInt(divWidth * (x / this.liveWidth));
                    y = parseInt(divHeight * (y / this.liveHeight));

                    this.playerDraw(x, y);
                } else {
                    this.presetPointVisible = false;   // 没有设置绘制的预置点则隐藏绘制
                }
            },

        }, // method 结束
        watch: {
            presetId(val) {
                var that = this;
                this.presetPointVisible = false;
                this.current_preset = this.getPresetById(this.presetId);
                if (this.current_preset == null) {
                    this.inputPresetName = "";
                    this.inputPresetCode = "";
                } else {
                    this.inputPresetName = this.current_preset.presetName;
                    this.inputPresetCode = this.current_preset.presetCode;
                }

                // setTimeout(function () {
                //     // 绘制预置点画面图形
                //     that.gotoPreset();
                // }, 200)

                setTimeout(function () {
                    if (!that.pointDrawClicked) {
                        // 绘制预置点画面图形
                        that.presetDraw();
                    }
                    that.pointDrawClicked = false;
                }, 2000)

            },
        },
    };
</script>

<style>
    * {
        font-size: 18px;
    }
    .icon-fj {
        /*display: flex;*/
        justify-content: space-around;
        width: 100%;
        height: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #3e3e3e;
    }
    .Central_content {
        display: flex;
        justify-content: space-around;
        width: 100%;
        /* background-color: black; */
    }
    body {
        background-color: #ffffff;
        padding-bottom: 0px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    select {
        width: 60px;
        margin-left: 10px;
    }

    /*控制画面框显示*/
    /* .parent-wnd div:not(:first-child) {
      display: none;
    } */
    .parent-wnd div {
        width: 100%;
    }
    .history {
        display: flex;
        justify-content: flex-start;

        flex-wrap: wrap;
        /* flex-direction: row; */
        width: 100%;
    }
    .image {
        margin-right: 10px;
        background-image: url(../assets/logo.png);
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
    }
    .camera_body {
        text-align: center;
    }

    /*@media screen and (min-width: 500px) {*/
    /*    #divPlugin {*/
    /*  height: 500px;*/
    /*  width: 100%;*/
    /*}*/
    /*}*/
    /*#divPlugin {*/
    /*  height: 225px;*/
    /*}*/

    .control_body {
        margin-top: 0px;
        color: #ffffff !important;
    }

    .control_line {
        width: 100%;
        text-align: center;
        background-color: #ffffff;
    }
    .select11{
        width:320px;
        border: 0;
        background-color: #fafbfc !important;
    }
    .select12{
        height:50px
    }

    .buttom_round {
        width: 120px;
        border-radius: 10px;
        color: #ffffff;
    }
    .buttom_round span {
        color: #ffffff;
    }

    /*方向按钮样式*/
    .bnt_left {
        color: black;
        transform: rotate(-45deg);
        border: 0px;
        border-top-left-radius: 100%;
        margin: 20px;
        background: radial-gradient(
                circle at bottom right,
                transparent 30px,
                #efefef 0
        )
        bottom right;
    }
    .bnt_right {
        color: black;
        transform: rotate(45deg);
        border-top-right-radius: 100%;

        border: 0px;
        background: radial-gradient(
                circle at bottom left,
                transparent 30px,
                #efefef 0
        )
        bottom left;
        /* margin: 20px; */
    }
    .bnt_bottom {
        color: black;
        position: relative;
        top: -30px;
        transform: rotate(-45deg);
        border: 0px;
        border-bottom-left-radius: 100%;
        background:
            /* radial-gradient(circle at top left, transparent 30px, #cfcece 0) top left, */ radial-gradient(
                circle at top right,
                transparent 30px,
                #efefef 0
        )
        top right;
        /* radial-gradient(circle at bottom right, transparent 30px, #cfcece 0) bottom right; */
        /* radial-gradient(circle at bottom left, transparent 30px, #fbf8f8 0) bottom left; */
    }
    .bnt_top {
        color: black;
        position: relative;
        top: 30px;
        transform: rotate(45deg);
        background: radial-gradient(
                circle at bottom right,
                transparent 30px,
                #efefef 0
        )
        bottom right;

        border: 0px;
        border-top-left-radius: 100%;
    }
    .bnt_top:hover {
        background: radial-gradient(
                circle at bottom right,
                transparent 30px,
                #1181ff 0
        )
        bottom right;
    }
    .bnt_right:active {
        color: black;
        transform: rotate(45deg);
        border-top-right-radius: 100%;
        border: 0px;
        background: radial-gradient(
                circle at bottom right,
                transparent 30px,
                #1181ff 0
        )
        bottom left;
    }
    .bnt_left:active {
        background: radial-gradient(
                circle at bottom right,
                transparent 30px,
                #1181ff 0
        )
        bottom right;
    }
    .bnt_bottom:active{
        background: radial-gradient(
                circle at bottom right,
                transparent 30px,
                #1181ff 0
        )
        top right;
    }

    .control_line button {
        width: 100px;
        height: 100px;
        margin: 5px;
        padding: 0px;
        font-size: 28px;
        text-align: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    div {
        color: black;
    }
    .control_focus_line {
        width: 100%;
        text-align: center;
        color: white;
        margin-top: 10px;
    }

    .control_focus_line button {
        width: 100px;
        height: 40px;
        margin: 10px 20px;
        padding: 0px;
        font-size: 15px;
        text-align: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .take_picture img {
        width: 600px;
    }

    .preset-control-title span {
        color: #7b7b7b;
    }

    .preset-control input {
        border-radius: 3px;
        outline: none;
        border: #939393 1px solid;
        padding: 2px;
    }

    .preset-control select {
        background-color: #FFFFFF;
        border-radius: 3px;
        outline: none;
        border: #939393 1px solid;
        padding: 2px;
    }

    .focus_div_top {
    }

    .focus_div_top .focus_top_btn {
        background-color: #efefef;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 50px;
        margin-left: -20px;
    }

    .focus_div_top .zoom_top_btn {
        background-color: #efefef;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 50px;
    }

    .focus_div_top .focus_bottom_btn {
        background-color: #efefef;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: 1px;
        width: 50px;
        margin-left: -20px;
    }

    .focus_div_top .zoom_bottom_btn {
        background-color: #efefef;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: 1px;
        width: 50px;
    }

    .focus_div_top span {
        color: #8b8b8b;
        font-size: 28px;
    }

    .debug {
        background-color: #d97e7e;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 15px;
    }
    .tubiao {
        width: 30px;
        height: 30px;
    }
    .quanping {
        background-image: url(../assets/fullscreen_o.png);
        background-size: cover;
        float: right;
        margin-right: 8px;
    }
    .jingyin {
        background-image: url(../assets/mute.png);
        background-size: cover;
    }

    .luzhi {
        background-image: url(../assets/record.png);
        background-size: cover;
    }

    .paizhao {
        background-image: url(../assets/picture.png);
        background-size: cover;
    }

    /* 方向控制按钮 */
    .ctl_btn_div {
        margin-top: 40px;
        margin-bottom: 30px;
    }
    .ctl_btn_div div {
        width: 100%;
    }
    .ctl_btn_top {
        width: 120px;
        height: 64px;
        content:url('../assets/ctl_top.png')
    }
    .ctl_btn_top_active {
        width: 120px;
        height: 64px;
        content:url('../assets/ctl_top_h.png')
    }
    .ctl_btn_bottom {
        width: 120px;
        height: 64px;
        margin-top: -32px;
        content:url('../assets/ctl_bottom.png')
    }
    .ctl_btn_bottom_active {
        width: 120px;
        height: 64px;
        margin-top: -32px;
        content:url('../assets/ctl_bottom_h.png')
    }
    .ctl_btn_left_right {
        width: 64px;
        margin-top: -32px;
    }
    .ctl_btn_right {
        margin-left: 64px;
        content:url('../assets/ctl_right.png')
    }
    .ctl_btn_right_active {
        margin-left: 64px;
        content:url('../assets/ctl_right_h.png')
    }
    .ctl_btn_left {
        content:url('../assets/ctl_left.png')
    }
    .ctl_btn_left_active {
        content:url('../assets/ctl_left_h.png')
    }
    /*.ctl_btn_left:active {*/
    /*    content:url('../assets/ctl_left_h.png')*/
    /*}*/

    /*#canvas1 {display: none;}*/
    /*#canvas_draw1 {display: none;}*/
</style>
