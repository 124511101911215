<template>

    <div id="body">

        <div v-if="isMobile" style="background-color: #151515;padding-bottom: 200px">
            <div style="width: 100%; text-align: center;padding-top: 10px; margin-bottom: 10px">
                <select v-model="cameraName" style="width: 160px" @change="selectCamera()">
                    <option v-for="(item,id) in cameras" :key="id" :value="item" :selected="true">
                        {{item}}
                    </option>
                </select>
            </div>
            <div class="player" id="tcplayer" ></div>
        </div>
        <div class="camera_line" style="width: 100%; text-align: center; background-color: #0c0c0c;" v-if="!isMobile">

<!--          style="width: 100%;"-->
            <table >
                <tr>
                    <th>
                        <div class="player" id="tcplayer1" ></div>
                    </th>
                    <th>
                        <div class="player" id="tcplayer2"  ></div>
                    </th>
                </tr>
                <tr>
                    <th>
                      <div class="player" id="tcplayer3"  ></div>
                    </th>
                    <th>
                      <div class="player" id="tcplayer4"  ></div>
                    </th>
                </tr>
            </table>


            <!-- 使用安卓时  -->
            <!-- 使用src=""， 使用:src="liveUrl"会导致video宽度0 -->
<!--            <video muted v-if="!isIPhone" id="livePlayer" controls autoplay>-->
<!--                <source type="application/x-mpegURL">-->
<!--            </video>-->

        </div>

<!--        <div style="width: 100%; text-align: center; color: #FFF; font-size: 24px; font-family: 'Cantarell Extra Bold'; margin-top: 10px">-->
<!--            当前播放器 : {{ player }}-->
<!--        </div>-->

    </div>

</template>

<script>
    export default {
        name: 'Index',
        components: {
        },
        data() {
            return {
                cameraName: "Camera01",
                cameras: ["Camera01","Camera02","Camera03","Camera04","Camera05","Camera06","Camera07","Camera08","Camera09",
                    "Camera10","Camera11","Camera12","TestCamera01"],
                // liveUrl: 'http://116.1.203.245:8088/live/0ffe395d13840329f0becb9b756e4bcdaf7d73f60e6d77ae6dc3949cb37e83ab/playlist.m3u8',
                // liveUrl: 'http://116.1.203.245:8088/live/7dc85bf2750db08ddf33c18ec4a9d04e2a3f6d0c3f03721986e25f090ac3eeda/playlist.m3u8',
                // liveUrl: 'http://116.1.203.245:8088/live/7e5cc74de49fbb44b2e611c87480a9ebe02fb565e6daa0b993807c565f0ee425/playlist.m3u8',
                liveUrl: 'http://116.1.203.245:8088/live/live/7e5cc74de49fbb44b2e611c87480a9ebe02fb565e6daa0b993807c565f0ee425/playlist.m3u8?token=dd44aa7962be9ddb75a9dd71b380b5f0dfe65ba56b848efb12a9fe42b8f4c141&cameraName=TestCamera01',
                liveUrls: [
                    "http://116.1.203.245:8088/live/live/44e7cbd32c9f1e0d4a4360649fe03d8816b265f663e7cf214f1629666ae89932/playlist.m3u8?token=019f7580392c8d935807d0ba2949002d0ac9aa53bd710b72f8729d452938fb90&cameraName=KGX-LIVE-SB-01",
                    "http://116.1.203.245:8088/live/live2/def559b88ba1e7f4724362e727b72eba386602cc1dcbedae4b2a47b06cd5dfd5/playlist.m3u8?token=4c436872597f21f4f9db5330993edcc4f8aa0077bf64527a57f613dcbdb6f578&cameraName=KGX-LIVE-SB-02",
                    "http://116.1.203.245:8088/live/live3/ddd18be2dc42a229f5fdf8d272c1e9d5c27d5538a008825b4deea1cca3aca30d/playlist.m3u8?token=3b757c0e62ae2abda3ccf45d47b102b99d0c0559a51c1f62d538e3a3ead39fe5&cameraName=KGX-LIVE-SB-03",
                    "http://116.1.203.245:8088/live/live2/c7801a8bf12fc743c4be3e567071bbdb7635953911ad894900523b51ab87b6bd/playlist.m3u8?token=038d72e1e63448e6974026ef1c400671469a147a68d893b8fbf69d6fb22e4e5b&cameraName=KGX-LIVE-SB-04",
                ],
                screenWidth: document.body.clientWidth,     // 屏幕宽
                screeHeight: document.body.clientHeight,    // 屏幕高
                // liveWidth: document.body.clientWidth / 5,
                // liveHeight: document.body.clientWidth * 720 / 1280 / 5,
                // liveWidth: document.body.clientWidth / 4.05,
                liveWidth: document.body.clientWidth / 2.50,
                liveHeight: document.body.clientWidth * 720 / 1280 / 2.50,
                liveFullWidth: document.body.clientWidth,
                liveFullHeight: document.body.clientWidth * 720 / 1280,
                speed: 4,    // 云台速度
                mouse_e: '',
                last_mouseup_eventtime: 0,
                mobilePlayer: null,
                player: "",
                seekingTimes: [],
                cameraStatus: [],
                players: []
            }
        },
        mounted() {
          // var vv = document.getElementById('vv')
          // vv.play()
            this.play();
            for (var i = 0; i < 12; i++) {
            // for (var i = 0; i < this.liveUrls.length; i++) {
                this.seekingTimes[i] = 0;
                this.cameraStatus[i] = 0;
            }
            if (this.isIPhone) {
                this.player = "TC Player";
            } else {
                this.player = "Video Player";
            }
            this.cameraStatusListening();
        },
        computed: {
            isIPhone() {
                return navigator.userAgent.toLowerCase().indexOf("iphone") != -1;
            },
            isMobile() {
                return this.getUrlKey("m") == 1 || (navigator.userAgent.toLowerCase().indexOf("iphone") != -1 || navigator.userAgent.toLowerCase().indexOf("android") != -1);
                // return true;
            }
        },
        methods: {
            getUrlKey: function (name) {
                return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
            },
            cameraStatusListening() {
                var that = this;
                setTimeout(function(){

                    console.log("")
                    for (let i = 0; i < that.cameraStatus.length; i++) {
                        that.cameraStatus[i] = that.cameraStatus[i] + 1;
                        console.log("状态: " + that.cameras[i] + " " + that.cameraStatus[i])
                        if (that.cameraStatus[i] >= 6) {
                            that.players[i].load(that.liveUrls[i]);
                            that.cameraStatus[i] = 0;
                            console.log(that.cameras[i] + " 播放中断，重新播放");
                        }
                    }
                    console.log("")

                    that.cameraStatusListening();

                },"3000");
            },
            play() {
                console.log("开始播放");

                // var livePlayer = document.getElementById("livePlayer");
                var tcPlayer = document.getElementById("tcplayer");
                if (false && !this.isIPhone) {      // iPhone手机隐藏 video 标签
                    // var width = document.body.clientWidth;
                    // var height = width * 1080 / 1920;
                    // livePlayer.style = "width: " + width + "px; height:" + height + "px";
                    // livePlayer.src = this.liveUrl;
                } else {
                    if (this.isMobile) {
                        var url = this.liveUrls[0];
                        // url = this.liveUrl;
                        this.playChannel(url, 0);
                    } else {
                        for (let i = 1; i <= this.liveUrls.length ; i++) {
                            var url = this.liveUrls[i - 1];
                            // url = this.liveUrl;
                            this.playChannel(url, i);
                        }
                    }
                }
            },
            playChannel(url, i) {
                console.log("连接: " + url);
                var playerName = this.isMobile ? 'tcplayer' : 'tcplayer' + i;
                var that = this;
                var width = this.isMobile ? this.liveFullWidth : this.liveWidth;
                var height = this.isMobile ? this.liveFullHeight: this.liveHeight;
                var player = new TcPlayer(playerName, {
                    "m3u8": url, //请替换成实际可用的播放地址
                    "autoplay" : true,      //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                    // "poster" : "http://www.test.com/myimage.jpg",
                    "width" :  width,//视频的显示宽度，请尽量使用视频分辨率宽度
                    "height" : height,//视频的显示高度，请尽量使用视频分辨率高度
                    "x5_player": true,
                    "live": true,
                    "x5_type": 'h5',
                    "listener": function (e) {

                        // seeked: 搜索

                        // if (e.type != "timeupdate" &&
                        //     e.type != "resize" &&
                        //     e.type != "progress" &&
                        //     e.type != "loadedmetadata" &&
                        //     e.type != "load" &&
                        //     e.type != "playing"
                        // ) {
                        //     console.log(playerName + "  " + e.type);
                        // }

                        // if (e.type != "progress" &&
                        //     e.type != "loadedmetadata" &&
                        //     e.type != "resize" &&
                        //     e.type != "timeupdate"
                        // ) {
                        //     console.log(playerName + "  " + e.type);
                        // }

                        // console.log(playerName + "  " + e.type);

                        // if (e.type == "loadeddata") {
                        //     console.log("tcplayer" + i + " 成功加载 " + e.type)
                        //     player.play();
                        // }

                        if (e.type == "progress") {
                            that.cameraStatus[i-1] = 0;
                        }

                        var reload = false;
                        // if (e.type == "seeking") {
                        //     that.seekingTimes[i-1] = that.seekingTimes[i-1] + 1;
                        //     if (that.seekingTimes[i-1] > 3) {
                        //         reload = true;
                        //     }
                        // }

                        if (e.type == "error") {
                            reload = true;
                        }

                        if (reload) {
                            // document.getElementById("tcplayer" + i).html("");
                            setTimeout(function(){
                                // player.destroy();
                                // that.playChannel(url, i);
                                console.log(playerName + " 连接错误，重新播放")
                                player.load(url);
                            },"4000");
                        }
                    }
                });
                player.mute(true)
                this.mobilePlayer = player;
                this.players[i-1] = player;
                player.play()
            },
            selectCamera(cameraName) {
                var index = 0;
                for (var i = 0; i < this.cameras.length; i++) {
                    if (this.cameras[i] == this.cameraName) {
                        index = i;
                    }
                }
                this.mobilePlayer.load(this.liveUrls[index]);
                console.log("播放: " + this.liveUrls[index]);
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    #body {
        width: 100%;
        height: 100%;
        background-color: #151515;
        /*padding-bottom: 30px;*/
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    tr {
        text-align: center;
    }
    th {
        /* text-align: center; */
        text-align: left;
        /*border: #232323 0px solid;*/
        border: none;
    }
    th div {
        /*margin-left: 120px;*/
        /*margin-left: 5px;*/
    }

    .stream_err {
        color: white;
    }

    .player {
        width: 100%;
        height: 100%;
    }

    .camera_line {
        /*width: 100%;*/
        /*margin-bottom: 20px;*/
        text-align: center;
    }

    .camera_play_left {
        width: 100%;
        float: left;
    }

    .camera_play_right {
        width: 100%;
        float: right;
    }

    .control_body {
        margin-top: 10px;
    }

    .control_line {
        width: 100%;
        text-align: center;
    }

    .control_line button {
        width: 80px;
        height: 80px;
        margin: 5px;
        padding: 0px;
        font-size: 28px;
        text-align: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .control_focus_line {
        width: 100%;
        text-align: center;
        color: white;
        margin-top: 10px;
    }

    .control_focus_line button {
        width: 100px;
        height: 50px;
        margin: 10px 20px;
        padding: 0px;
        font-size: 20px;
        text-align: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

</style>
